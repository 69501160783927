// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  TextField,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import StepperData from '../../../components/src/StepperData.web';
import FooterData from "../../../components/src/SignupFooter.web";
import * as Yup from "yup";
import Select, { ValueType } from "react-select";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import Pagination from '@mui/material/Pagination';
import { styled } from "@mui/styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Spinner from "./components/Spinner.web";

import CustomFormController, {
  Props,
  Touched,
  Error,
  Dropdown,
  Data,
} from "./CustomFormController.web";

const StyledWrapperCoverageDecision = styled('div')({
  width: "100%",
  margin: "0 auto",
  "& *": {
      boxSizing: "border-box",
  },
  "& .information": {
      maxWidth: "805px",
      margin: "auto",
      fontFamily: "Poppins",
      fontWeight: 700,
      fontSize: "24px",
      color: "rgba(44, 37, 36, 1)",
      lineHeight: "36px",
  },
  "& .headerStepper": {
    justifyContent: "center",
    display: "flex",
},
  "& .sub-information": {
      textAlign: 'center',
      maxWidth: "805px",
      margin: "0px auto 32px",
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "24px",
      color: "rgba(44, 37, 36, 1)",
      lineHeight: "36px",
  },
  "& .heading": {
      margin: "auto",
      fontWeight: 400,
  },
  "& .backBtn": {
      height: "auto",
      color: "#78716C",
      width: "100%",
      marginLeft: "15%",
      maxWidth: "15%",
      marginBottom: "63px",
      fontSize: '20px',
      fontfamily: 'Raleway'
  },
  "& .back-container": {
      color: "#78716C",
      fontSize: '20px',
      fontFamily: 'Raleway',
      marginTop: '-23px',
      fontWeight: 700
  },
  "& .secondaryText": {
    justifyContent: "center",
    letterSpacing: "1px",
    lineHeight: "23.48px",
    fontFamily: "Raleway",
    display: "flex",
    background: "none",
    fontWeight: 700,
    color: "#807C7C",
    fontSize: "20px",
    border: "none",
    cursor: "pointer",
    textDecoration: 'underline',
    marginTop: '24px',
    margin: 'auto',
    marginBottom: '40px',
},
  "& .button": {
    marginBottom: '12px',
    fontFamily: "Raleway",
    display: "flex",
    fontSize: "20px",
    justifyContent: "center",
    width: "392px",
    padding: '16.5px',
    borderRadius: "24px",
    border: '2px solid #E46760',
    background: "none",
    fontWeight: 700,
    color: '#E46760',
    margin: "auto",
      alignItems: "center",
      letterSpacing: '1px'
  },
  '& .button.selected': {
    'backgroundColor': '#E46760',
    color: '#fff',
},
  "& .secondary-btn": {
    lineHeight: "23.48px",
    textDecoration: 'underline',
    justifyContent: "center",
    background: "none",
    letterSpacing: "1px",
    fontFamily: "Raleway",
    fontWeight: 700,
    margin: 'auto',
    display: "flex",
    border: "none",
    cursor: "pointer",
    fontSize: "20px",
    color: "#E46760",
  },
  "& .box-title": {
      color: "#2C2524",
      marginBottom: '32px',
      fontSize: "29px",
      letterSpacing: '0.5px',
      fontWeight: 500,
      fontFamily: "Poppins",
      textAlign: "center",
      width: "100%",
  },
  '& .main-box': {
      padding: '32px'
  },
  "& .box-one": {
    width: '456px',
    background: 'rgba(255, 250, 246, 1)',
    border: "1px solid #D6D3D1",
    borderRadius: "24px",
    margin: 'auto',
  },
  "& .leftSideBorder": {
    borderLeft: "32px solid #1A4A42",
    height: "auto",
  },
  "& .boxes": {
    gap: "20px",
    width: "100%",
    marginBottom: "23px",
    display: "flex",
    flexDirection: "row",
    height: "auto",
  },
  "& .image": {
    width: "20%",
      height: "20%",
  },
  "& .title": {
      width: "100%",
      height: "auto",
      maxWidth: "60%",
      color: "#2C2524",
      margin: "60px auto 32px ",
      fontWeight: 700,
      fontSize: "50px",
      fontFamily: "Raleway",
      textAlign: "center",
      LineHeight: '60px',
      LetterSpacing: '0.5px'
  },
})
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userSchema = () => {
    return Yup.object().shape({
      firstName: Yup.string().required("*First name is required!"),
      lastName: Yup.string().required("*Last name is required!"),
      phoneNumber: Yup.string().required("*Phone number is required!"),
      email: Yup.string().required("*Email is required!"),
      organization: Yup.string().required("*Organization is required!"),
      teamName: Yup.string().required("*Team name is required!"),
      userType: Yup.string().required("*User type is required!"),
      rating: Yup.string().required("*Rating type is required!"),
      gender: Yup.string().required("*Gender is required!"),
      address: Yup.string().required("*Address is required!"),
      country: Yup.string().required("*Country is required!"),
      state: Yup.string().required("*State is required!"),
      city: Yup.string().required("*City is required!"),
      file: Yup.string().required("*File is required!").nullable(),
    });
  };

  getErrorMessage = (
    touched: FormikTouched<Touched>,
    errors: FormikErrors<Error>,
    value: string
  ) => {
    return (
      touched[value as keyof Error] &&
      errors[value as keyof Error] && (
        <Typography
          style={{ marginTop: "2px", fontSize: "14px", color: "#f94b4b" }}
        >
          {errors[value as keyof Error]}
        </Typography>
      )
    );
  };

  getValue = (event: ValueType<Dropdown, false>) => {
    return event ? event.value : "";
  };

  buttons = [
    'Original Medicare',
    'Medicare Advantage',
    'Other Insurance',
];

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
       <StyledWrapperCoverageDecision>
                <section className='leftSideBorder'>
                    <Box className="mainContainer">

                        <Box className="headerStepper">
                            <StepperData
                                key={`stepper${this.state.activeStep}`}
                                listData={this.state.listData}
                                activeStep={this.state.activeStep}
                            />
                        </Box>
                        <Box className="heading">
                            <Typography className="title">Check Your Coverage</Typography>
                            <Typography className="information">your insurance plan may cover up to 100% of your sessions.</Typography>
                            <Typography className="sub-information">Please select your preferred payment method</Typography>
                        </Box>
                        <Box className="boxes">
                            <Box className="box-one">
                                <Box className="main-box">
                                    <Typography className="box-title">Insurance</Typography>
                                    <Box className="content">
                                    {this.buttons.map((buttonName) => (
                    <button
                        key={buttonName}
                        data-test-id= 'continuebtn'
                        className={`button ${this.state.selectedButton === buttonName ? 'selected' : ''}`}
                        onClick={() => this.handleButtonClick(buttonName)}
                    >
                        {buttonName}
                    </button>
                ))}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{display: 'block'}}>
                            <Box>
                                
                            </Box>
                            <button className="button">I want to pay with cash</button>
                            <button className="secondaryText">I'm not sure</button>
                            <button className="secondary-btn">I don't have my insurance details on me</button>
                        <Box className="back-container">
                            <Typography 
                            data-test-id="back-btn"
                            onClick={() => {
                              this.props.navigation.goBack();
                            }}
                            className="backBtn">Back</Typography>
                        </Box>
                        </Box>
                    </Box>
                    <FooterData />
                </section>
        </StyledWrapperCoverageDecision>
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
