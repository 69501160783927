import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  Select,
  TextField, Divider, MenuItem, FormControl, Typography
} from "@mui/material";
import { Event } from "@mui/icons-material";
import DatePicker from "react-multi-date-picker";
import { HeaderLogoImg } from "./assets";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ConditionalRenderer, ResolveCondition } from "../../../blocks/utilities/src/CustomHelpers";
import StepperData from '../../../components/src/StepperData.web';
import PhoneInput from 'react-phone-input-2'
import SignupFooter from "../../../components/src/SignupFooter.web"
// Customizable Area End

import WellnessJourneyController, { Props } from "./WellnessJourneyController";
import PageNotFound from '../../../components/src/PageNotFound.web'

export default class WellnessJourney extends WellnessJourneyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  validateEmail = (email:any) => {
    const regular_expressions =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regular_expressions.test(String(email).toLowerCase());
  };

  checkInputs = (): boolean => { 
    const {selectedMethods,dateOfBirth, phone_numbers ,email_Field,freeTextField,dropDownState,customDropDownState} = this.state;
    let allError: any = {
      multipleCheckBoxError: "",
      dateOfBirthError: "",
      phone_numbers: "",
      email_Field: [], 
      freeTextFieldError: "",
      dropDownStateError: "",
      customDropDownError: ""
    };
    let isValid = true;
    const emailFields = Array.isArray(email_Field) ? email_Field : []; 
    if (!phone_numbers || phone_numbers.length === 0) {
      allError.phone_numbers = "Required";
      isValid = false;
    }
   
    if (!emailFields || emailFields.length === 0) {
      allError.email_Field = "Email format is invalid";
      isValid = false;
    }
    if (!emailFields || emailFields.length === 0) {
      allError.email_Field = "Email cannot be blank.";
    } else if (!this.validateEmail(emailFields)) {
      allError.email_Field = "Email is not valid.";
    }
    if (!freeTextField || freeTextField.length === 0) {
      allError.freeTextFieldError = "Required";
      isValid = false;
    }
    if (!dropDownState || dropDownState.length === 0) {
      allError.dropDownStateError = "Required";
      isValid = false;
    }
    if (!customDropDownState || customDropDownState.length === 0) {
      allError.customDropDownError = "Required";
      isValid = false;
    }
    if (!dateOfBirth || dateOfBirth.length === 0) {
      allError.dateOfBirthError = "Required";
      isValid = false;
    }
    if (!selectedMethods || selectedMethods.length === 0) {
      allError.multipleCheckBoxError = "Required";
      isValid = false;
    }
    this.setState({ allError });
    return isValid;
  };
  renderError = (touched: any, fieldStats: any) => {
    return ConditionalRenderer(Boolean(touched && fieldStats), <div className="error">{fieldStats}</div>, '')
  }
 
  handleEmailField = (item: any) => {
    return (
      <>
        {item.field_type == "email_field" && item.options.map((emailOption: any, index: number) => {
          const fieldName = `email.${index}`;
        
          return (
            <>
            <Box  className="emailFieldClass">
                  <div className="heading">{emailOption.label}
                    {ConditionalRenderer(emailOption?.is_mandatory, <span className='required-text'>*</span>, '')}
                  </div>
                  <Box className="inputField">
                
                <TextField
                  name={fieldName}
                  className="emailField inputFields"
                  placeholder="Enter Email address"
                  data-test-id="txtInput2"
                  type="email"
                  value={this.state?.email_Field[index] || ''}
                  onChange={(e: any) => {
                    const updatedState = [...this.state.email_Field]; 
                    updatedState[index] = e.target.value;
                    this.setState({ email_Field: updatedState });
                }} 
                />
                   {ConditionalRenderer(emailOption?.is_mandatory && Boolean(this.state.allError.email_Field?.[index]),
                    <div className="error-container">
                      <div className="error">
                        {this.state.allError.email_Field}
                      </div>
                    </div>, ''
                  )}
                   </Box>
            </Box>
            </>
          )
        })}
      </>
    )
  }
  handleMultipleChecbox = (item: any) => {
    return (
      <>
        {item.field_type == "multiple_checkbox" && (
          <>
            <div className="heading">
              {item?.heading}
              {ConditionalRenderer(item?.options[0]?.is_mandatory, <span className='required-text'>*</span>, '')}
            </div>
            <Box style={{ display: 'flex', gap: '20px' }}>
              {item.options.map((multipleCheckBoxOption: any, index: number) => {
                const isChecked = this.state.selectedMethods.includes(multipleCheckBoxOption.id);
                return (

                  <Box className="checkBoxGroup" key={index}>
                    <label style={webstyle.checkboxLabel}>
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={this.handleCheckboxChange(multipleCheckBoxOption.id)}
                        name="contact_method"
                        style={webstyle.checkboxInput}
                        data-test-id="phoneCheckbox"
                      />
                      <span style={webstyle.checkboxCustom1(isChecked)}>
                        {<span style={webstyle.checkmark}></span>}
                      </span>
                      <span className="phoneClass">{multipleCheckBoxOption.label}</span>
                    </label>
                   
                  </Box>
                );
              })}
              
            </Box>
            {ConditionalRenderer(item?.options[0]?.is_mandatory && Boolean(this.state.allError.multipleCheckBoxError ),
                        <div className="error-container">
                          {ConditionalRenderer(Boolean(this.state.allError.multipleCheckBoxError),
                            <div className="error">
                              {this.state.allError?.multipleCheckBoxError}
                            </div>, ''
                          )}
                        </div>, ''
                      )}
          </>)}


      </>
    )
  }
  handleSimpleTextField = (item: any) => {
    return (
      <>
        {item.field_type == "simple_text_field" && item.options.map((textFielldOption: any, index: number) => {
          return (
            <>
                <Box className="div-container">
                <div className="heading">{textFielldOption?.label}
                  {ConditionalRenderer(textFielldOption?.is_mandatory, <span className='required-text'>*</span>, '')}
                </div>
                <Box className="inputField">
                 <TextField
                  data-test-id="freeTextField"
                  className="emailField inputFields"
                  type="text"
                  value={this.state?.freeTextField[index] || ''}
                  onChange={(e: any) => {
                    const updatedState = { ...this.state.freeTextField, [index]: e.target.value };
                    this.setState({ freeTextField: updatedState });
                  }}       />
                   {ConditionalRenderer(textFielldOption?.is_mandatory && Boolean(this.state.allError.freeTextFieldError),
                    <div className="error-container">
                      <div className="error">
                        {this.state.allError.freeTextFieldError}
                      </div>
                    </div>, ''
                  )}
                </Box>
              
              </Box>
            </>
          )
        })}
      </>
    )
  }
  handleMobileNumberField = (item: any) => {
    return (
      <>
        {item.field_type == "mobile_number_field" && item.options.map((optionItem: any, index: number) => {
          const fieldName = `phone_numbers.${index}`;
          const landlineFieldName = `landline_number.${index}`;
          const isLandlineChecked = this.state.landline_no && this.state.landline_no[index];
          const errorLandlineNumber = this.state.allError[landlineFieldName];

          return (
            <>
              <Box marginTop="24px">
                  <Box className="div-container">
                    <div className="heading">{optionItem?.label}
                      {ConditionalRenderer(optionItem?.is_mandatory, <span className='required-text'>*</span>, '')}
                    </div>
                    <Box className="inputField">
                      <PhoneInputWrapper>
                        <PhoneInput
                          country={'us'}
                          placeholder='Enter your phone number'
                          value={fieldName[index] || ''}
                          data-testid="mobile_number"
                          onChange={(phone) => {
                            this.setState({ phone_numbers: phone  });
                          }}
                        />
                      </PhoneInputWrapper>
                      {ConditionalRenderer(optionItem.is_mandatory && Boolean(this.state.allError.phone_numbers),
                        <div className="error-container">
                          {ConditionalRenderer(Boolean(this.state.allError.phone_numbers),
                            <div className="error">
                              {this.state.allError?.phone_numbers}
                            </div>, ''
                          )}
                        </div>, ''
                      )}
                    </Box>
                    <Box className="checkboxContainer">
                      <label style={webstyle.checkboxLabel}>
                        <input
                          type="checkbox"
                          checked={isLandlineChecked || false}
                          onChange={() => {
                            this.setState(prevState => ({
                              landline_no: {
                                ...prevState.landline_no,
                                [index]: !isLandlineChecked,
                              },
                            }));
                          }}
                          style={webstyle.checkboxInput}
                          data-test-id="landline"
                        />
                        <span style={webstyle.checkboxCustom(isLandlineChecked)}>
                          {<span style={webstyle.checkmark}></span>}
                        </span>
                        <span className="checkboxText">I don't have a mobile - add a landline</span>
                      </label>
                    </Box>
                    {
                      ConditionalRenderer(isLandlineChecked, <Box className="inputField">
                        <PhoneInputWrapper>
                          <PhoneInput
                            country={'us'}
                            placeholder='Enter your phone number'
                            value={this.state?.landline_number[index] || ''}
                            data-test-id="landline_number"
                            onChange={(landline_no: any) => {
                              this.setState(prevState => ({
                                therapyLadlineNo: landline_no,
                              }));
                            }}
                          />
                        </PhoneInputWrapper>
                      </Box>, '')
                    }
                  </Box>
                
              </Box>
            </>
          )
        })}
      </>
    )
  }
  handleSingleCheckbox = (item: any) => {
    return (
      <>
        {item?.field_type == "single_checkbox" && item.options.map((singleCheckOprtions: any) => {
          return (
            <>
            
                <Box className="checkboxContainerLast">
                  <label
                    style={webstyle.checkboxLabel1}
                  >
                    <input
                      type="checkbox"
                      style={webstyle.checkboxInput1}
                      name="agree"
                      data-test-id="consentAgreeCheck"
                      checked={this.state.consentChecked}
                      onChange={(e) =>
                        this.handleConsentChange(e.target.checked)
                      }
                    />
                    <span
                      style={webstyle.checkboxCustom1(this.state.consentChecked)}
                    >
                      {<span style={webstyle.checkmark1}></span>}
                    </span>
                    <span className="phoneClass">
                      {singleCheckOprtions.label}
                    </span>
                  </label>
                </Box>
              
            </>
          )
        })}
      </>
    )

  }
  handleCustomDropDown = (item: any,IconComponent1:any) => {
    return (
      <>
       {item.field_type == "custom_dropdown" && item.options.map((dropdownOptions: any, index: number) => {
          const fieldName = `customDropDown.${index}`;
          return (
            <React.Fragment key={dropdownOptions.label}>
                 <Box>
                  <div className="heading">{dropdownOptions.label}
                    {ConditionalRenderer(dropdownOptions?.is_mandatory, <span className='required-text'>*</span>, '')}
                  </div>
                  <FormControl className="inputFieldLast1" fullWidth>
                  <Select
                      value={this.state.customDropDownState[index] || ''}
                      displayEmpty
                      placeholder="Select One"
                      data-test-id="customDropdown"
                      onChange={(e: any) => {
                        const updatedState = { ...this.state.customDropDownState, [index]: e.target.value };
                        this.setState({ customDropDownState: updatedState });
                      }}
                      name={fieldName}
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            border: "1px solid #E6C79C",
                            borderRadius: "0px 0px 24px 24px",
                          },
                        },
                      }}
                      sx={{
                        border: "1px solid #E6C79C",
                        borderRadius: "0px 0px 24px 24px",
                        borderTop: "none",
                        padding: "12px",
                        "& fieldset": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          borderRadius: "16px",
                        },
                      }}
                    
                      IconComponent={IconComponent1}
                      >
                   {dropdownOptions?.content?.map((item: string, idx: number) => {
                    return(
                    <MenuItem
                      key={index}
                      value={item}
                      sx={{
                        borderBottom: "1px solid #D6D3D1",
                        fontWeight: 400,
                        padding: "8px 12px 8px 16px",
                        height: "40px",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        lineHeight: "24px",
                        "&:hover": {
                          backgroundColor: "#e0e0e0",
                        },
                      }}
                    >
                      {item}
                    </MenuItem>
       )})}
                </Select>
                  
                    {ConditionalRenderer(dropdownOptions?.is_mandatory && Boolean(this.state.allError.customDropDownError),
                      <div className="error-container">
                        {ConditionalRenderer(Boolean(this.state.allError?.customDropDownError),
                          <div className="error">
                            {this.state.allError?.customDropDownError}
                          </div>, ''
                        )}
                      </div>, ''
                    )}
                  </FormControl>
                </Box>
              
            </React.Fragment>
          )
        })}
      </>
    )
  }
  handleStateFunction =  (item: any,IconComponent:any) => {
    return (
      <>
        {item.field_type == "states" && item.options.map((stateOptions: any,index:number) => {
         const fieldName = `dropDownState.${index}`;
          return (
            <>
             <Box>
                  <div className="heading">{stateOptions.label}
                    {ConditionalRenderer(stateOptions?.is_mandatory, <span className='required-text'>*</span>, '')}
                  </div>
                  <FormControl className="inputFieldLast1" fullWidth>
                   <Select
                      name={fieldName}
                      value={this.state.dropDownState[index] || ''}
                      data-test-id="stateID"
                      displayEmpty
                      placeholder="Select One"
                      MenuProps={{
                        PaperProps: {
                          sx: {
                            border: "1px solid #E6C79C",
                            borderRadius: "0px 0px 24px 24px",
                          },
                        },
                      }}
                      sx={{
                        border: "1px solid #E6C79C",
                        borderRadius: "0px 0px 24px 24px",
                        borderTop: "none",
                        padding: "12px",
                        "& fieldset": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          borderRadius: "16px",
                        },
                      }}
                      onChange={(e: any) => {
                        const updatedState = { ...this.state.dropDownState, [index]: e.target.value };
                        this.setState({ dropDownState: updatedState });
                      }}
                      IconComponent={IconComponent}
                    >
                  {this.state.stateDropdown?.map((item:any, index:any) => (
                    <MenuItem
                      key={index}
                      value={item}
                      sx={{
                        borderBottom: "1px solid #D6D3D1",
                        fontWeight: 400,
                        padding: "8px 12px 8px 16px",
                        height: "40px",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        lineHeight: "24px",
                        "&:hover": {
                          backgroundColor: "#e0e0e0",
                        },
                      }}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                    {ConditionalRenderer(stateOptions?.is_mandatory && Boolean(this.state.allError?.dropDownStateError),
                      <div className="error-container">
                        {ConditionalRenderer(Boolean(this.state.allError?.dropDownStateError),
                          <div className="error">
                            {this.state.allError?.dropDownStateError}
                          </div>, ''
                        )}
                      </div>, ''
                    )}
              </FormControl>
             </Box>
            </>
          )
        })}
      </>
    )
  }
 
  handleSubmit = (e:any) => {
    e.preventDefault();
    const url = this?.state?.signUPFormvalue?.data?.attributes?.primary_links_section4_attributes[0]?.primary_link_url 
    const linkUrl = url ? url : ""
    const isFormValid: boolean = this.checkInputs();
    if (isFormValid) {
      this.handleNavigation(linkUrl);
    }
  }

  handleContinueButton = (signUPFormvalue:any) => {
    return (
     <>
     {signUPFormvalue.data.attributes.primary_links_section4_attributes.map((item: any) => {
        return (
          <button
            className={`button-continue ${ResolveCondition(
              true,
              "active",
              "inactive"
            )}`}
            disabled={!this.state.selectedValue}
            data-test-id="submitBtn"
            type="submit"
          >
            {item?.primary_button_text}
          </button>
        )
      })}
 </>

    );
}

  
  // Customizable Area End

  render() {
    const IconComponent = this.state?.open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;
    const IconComponent1 = this.state?.open ? KeyboardArrowUpIcon : KeyboardArrowDownIcon;

    const { signUPFormvalue } = this.state;
    return (
      // Customizable Area Start
      <>
      {this.state.message ? <PageNotFound /> : 
        <section style={{ borderLeft: "32px solid #1A4A42" }}>
          <StyledWrapperForWellness>
            <Box className="headerLogo">
              <img className="logoImg" alt="HeaderLogoImgs" src={HeaderLogoImg} />
            </Box>
            <Divider />
            <Box className="headerSteppers">
              <StepperData
                key={`stepper${this.state.formStats.stage_type}`}
                listData={this.state.formStats.progress_bars}
                activeStep={this.state.formStats.stage_type}
              />
            </Box>

            <Box height="auto">
                  <form onSubmit={this.handleSubmit}
                  >
                    <Box className="main-section">
                      <h2 className="questions">
                        {signUPFormvalue?.data.attributes.main_title4}
                      </h2>
                      <p className="information">
                        <Typography className="information"
                          dangerouslySetInnerHTML={{
                            __html:
                              signUPFormvalue?.data?.attributes?.sub_title4
                          }}
                        />
                      </p>

                      <Divider sx={{
                        borderColor: '#000000',
                        width: '700px',
                        margin: '0 auto'
                      }} />
                      {signUPFormvalue?.data.attributes?.sign_up_form_sections.map((item: any) => {
                        return (
                          <>
                            <Box className="box">
                              <Box>
                                {this.handleMobileNumberField(item)}
                              </Box>
                              <Box>
                              {this.handleEmailField(item)}
                              </Box>
                              <Box>
                                {this.handleMultipleChecbox(item)}
                              </Box>
                              <Box sx={{ marginTop: "24px" }}>
                                {this.handleSimpleTextField(item)}
                              </Box>
                              <Box className="stateDropDown">
                                {this.handleStateFunction(item,IconComponent)}
                              </Box>
                              <Box className="customDropDown">
                              {this.handleCustomDropDown(item,IconComponent1)}
                              </Box>
                              {this.handleSingleCheckbox(item)}
                              <Box>
                                {item.field_type == "date" &&
                                  (item.options.map((dateOptions: any,index:any) => {
                                    return (
                                      <>
                                      <Box marginBottom="24px">
                                        <div className="heading">{dateOptions?.label}
                                          {ConditionalRenderer(dateOptions?.is_mandatory, <span className='required-text'>*</span>, '')}
                                        </div>
                                        <Box style={{ position: 'relative' }} ref={this.dropDownRef}>
                                          <DatePicker
                                            ref={this.datePickerRef}
                                            placeholder="MM-DD-YYYY"
                                            data-testid="date_of_birth"
                                            format="MM-DD-YYYY"
                                            name={`dateOfBirth_${index}`}
                                            required={true}
                                            className="datePickerStyle"
                                            value={this.state?.dateOfBirth?.[index] || null}
                                            onChange={(date) => {
                                              const updatedDateOfBirth = [...(this.state.dateOfBirth || [])];  
                                              updatedDateOfBirth[index] = date ? date.format() : null; 
                                              this.setState({ dateOfBirth: updatedDateOfBirth });
                                            }}
                                            style={{
                                              width: '600px',
                                              borderRadius: '24px',
                                              border: '1px solid #E6C79C',
                                              padding: '10px 40px 10px 10px',
                                              height: "100%",
                                              maxHeight: "44px",
                                              fontFamily: "Poppins",
                                              fontWeight: 400,
                                              fontSize: "16px",
                                              lineHeight: "24px",
                                            }}
                                          />
                                          <Event style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            pointerEvents: 'none',
                                          }} className="calendar-icon" />
                                        </Box>
                                        {ConditionalRenderer(dateOptions?.is_mandatory && Boolean(this.state.allError?.dateOfBirthError),
                                           <div className="error-container">
                                             {ConditionalRenderer(Boolean(this.state.allError?.dateOfBirthError),
                                               <div className="error">
                                                 {this.state.allError?.dateOfBirthError}
                                               </div>, ''
                                             )}
                                           </div>, ''
                                        )}  
                                        </Box>                                   
                                      </>
                                    )
                                  })

                                  )}
                              </Box>
                              {item.field_type == "single_line_checkbox" && item.options.map((singleCheckBox: any) => {
                                return (
                                 
                                      <Box className="checkboxContainerLast">
                                        <label style={webstyle.checkboxLabel1}>
                                          <input
                                            type="checkbox"
                                            style={webstyle.checkboxInput1}
                                            name="agree"
                                            data-test-id="agreeCheck"
                                            checked={this.state.agreeChecked}
                                            onChange={(e) =>
                                              this.handleAgreeChanges(e.target.checked)
                                            }
                                          />
                                          <span
                                            style={webstyle.checkboxCustom1(this.state.agreeChecked)}
                                          >
                                            {<span style={webstyle.checkmark1}></span>}
                                          </span>
                                          <Typography
                                            className="singleLineCheckBox"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                singleCheckBox?.statement
                                            }}
                                          />
                                        </label>
                                      </Box>
                                 
                                )
                              })}


                            </Box>
                          </>
                        )
                      })}
                      <Box className="box">
                        <Box>

                          <Box marginBottom="28px">
                            {this.handleContinueButton(signUPFormvalue)}
                          </Box>


                        </Box>
                        <Box className="loginClass">
                          {signUPFormvalue.data.attributes.secondary_links_section3_attributes.map((item: any) => {
                            return (
                              <>
                                <u
                                data-test-id="secondaryLink"
                                onClick={() => {
                                
                                  this.handleNavigation(item?.secondary_link_url)
                                }} 
                                
                                className="content">
                                  {item?.secondary_button_text}
                                </u>
                              </>
                            )
                          })}

                        </Box>

                      </Box>

                    </Box>
                  </form>

                </Box>

            <Box className="backButton" >
              <div
                data-test-id="BackBtn"
                className="greyColor"
                onClick={() => {
                  this.props.navigation.goBack();
                }}
              >
                Back
              </div>

            </Box>
          </StyledWrapperForWellness>
          <Box sx={webstyle.footerClass} >
            {signUPFormvalue?.data?.attributes?.help_footer && <SignupFooter />}
          </Box>
        </section>
      }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const StyledWrapperForWellness = styled("div")({
  "& .full-name": {
    // marginTop: "32px"
  },
  "& .inputFieldLast1 .MuiOutlinedInput-root": {

    "&:hover fieldset": {
      border: "1px solid #E6C79C",
    },
    "& fieldset": {
      border: "1px solid #E6C79C",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E6C79C",
    },
  },
  "& .inputFieldLast1 .MuiInputBase-root.MuiOutlinedInput-root":
  {
    padding: "10px 30px",
    borderRadius: "24px",
    maxWidth: "100%",
    border: "0px solid #E6C79C",
    width: "100%",
    maxHeight: "44px",
  },
  "& .inputFieldLast1": {
    maxHeight: "72px",
    borderRadius: "24px",
    maxWidth: "100%",
    width: "100%",
    marginBottom: "24px",
    "& .MuiSelect-select": {
      marginLeft: "-5%",
      display: "flex"
    },
    "& .MuiSelect-outlined": {
      marginLeft: "-5%",
      display: "flex"
    }
  },
  "& .otherInputFieldLast1 .MuiInputBase-root.MuiOutlinedInput-root":
  {
    padding: "10px 4px",
    borderRadius: "24px",
    maxWidth: "100%",
    border: "0px solid #E6C79C",
    width: "100%",
    maxHeight: "44px",
  },
  "& .otherInputFieldLast1": {
    maxHeight: "72px",
    borderRadius: "24px",
    maxWidth: "100%",
    width: "100%",
    marginBottom: "9px",
    "& .MuiSelect-select": {
      marginLeft: "-5%",
      display: "flex"
    },
    "& .MuiSelect-outlined": {
      marginLeft: "-5%",
      display: "flex"
    }
  },
  "& .otherInputFieldLast1 .MuiOutlinedInput-root": {

    "&:hover fieldset": {
      border: "1px solid #E6C79C",
    },
    "& fieldset": {
      border: "1px solid #E6C79C",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E6C79C",
    },
  },
  "& .date-picker-container .inputFields": {
    height: "100%",
    width: "100%",
    maxHeight: "44px",
    maxWidth: "288px",
    background: "#FFFFFF",
  },
  "& .greyColor": {
    color: "#807C7C",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    cursor: "pointer",
  },
  "& .backButton": {
    marginTop: "-123px",
    marginLeft: "115px",

    "@media screen and (max-width:1000px)": {
      marginLeft: "60px",
    },
    "@media screen and (max-width:850px)": {
      marginLeft: "20px",
    },
    "@media screen and (max-width:800px)": {
      marginLeft: "8px",
    },
    "@media screen and (max-width:750px)": {
      marginTop: "498px",
      marginLeft: "22px",
    },
    "@media screen and (max-width:700px)": {
      marginTop: "498px",
      marginLeft: "5px",
    },
    "@media screen and (max-width:650px)": {
      marginTop: "497px",
      marginLeft: "5px",
    },
    "@media screen and (max-width:600px)": {
      marginTop: "577px",
      marginLeft: "9px",
    },
    "@media screen and (max-width:500px)": {
      marginTop: "630px",
      marginLeft: "3px",
    },
  },
  "& .date-picker-container .rmdp-top-class": {
    backgroundColor: "#FFFFFF",
  },
  "& .date-picker-container .rmdp-container": {
    width: "100% !important"
  },
  "& .date-picker-container .inputFields input::placeholder": {
    fontSize: "15px",
    fontFamily: "Poppins",
  },
  "& .date-picker-container": {
    "position": "relative",
    "width": "100%",
    "maxWidth": "288px",
    "height": "44px",
  },

  "& *": {
    boxSizing: "border-box",
  },
  "& .required-text": {
    textFillColor: "#DC2626",
    WebkitTextFillColor: "#DC2626",
    margin: "3px"
  },
  "& .MuiFormControl-root.MuiTextField-root.emailField.inputFields": {
    width: "100% !important"
  },
  "& .emailField .MuiOutlinedInput-root": {
    width: "100%",
    height: "42px",
    maxWidth: "600px",
    borderRadius: "24px",
    borderColor: "#E6C79C",

    "& fieldset": {
      borderColor: "#E6C79C",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E6C79C",
    },
    "&:hover fieldset": {
      borderColor: "#E6C79C",
    },
    "& .MuiInputBase-input": {
      height: "100%",
    }
  },
  "& .MuiCheckbox-root.Mui-checked svg": {
    color: "#1A4A42",
    borderRadius: "4px",
  },
  "& .headerSteppers": {
    "display": "flex",
    "paddingTop": "32px",
    "justifyContent": "center",
  },
  "& .MuiCheckbox-root svg": {
    borderRadius: "10px",
  },
  "& .PhoneInputInput::placeholder, & .MuiInputBase-input::placeholder": {
    fontFamily: "Poppins",
    fontSize: "16px",
  },
  "& .header .custom-step-connector .MuiStepConnector-root": {
    display: "none",
  },
  "& .header .custom-step-connector .MuiStepper-horizontal": {
    justifyContent: "space-between",
    position: "relative",
  },
  "& .header .custom-step-connector .MuiSvgIcon-root": {
    borderRadius: "10px",
  },
  "& .inputField .inputFields .MuiInputBase-root": {
    width: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    borderRadius: "24px"
  },
  "& .inputField .inputFields .MuiOutlinedInput-root": {
    width: "100%",
    maxHeight: "42px",
    borderRadius: "24px",
    borderColor: "#E6C79C",
    "& fieldset": {
      borderColor: "#E6C79C",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E6C79C",
    },
    "&:hover fieldset": {
      borderColor: "#E6C79C",
    },
  },
  "& .header .custom-step-connector .MuiStepLabel-iconContainer::after":
  {
    height: "3px",
    backgroundColor: "#A8A29E",
    content: `""`,
    position: "absolute",
    width: "239px",
    left: "44px",
    top: "10px",
  },
  "& .Select": {
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#78716C",
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .error-container": {
    // minHeight: "20px",
  },
  "& .error": {
    color: "#DC2626",
    textAlign: "initial",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19.5px",
  },
  "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
    width: "100%",
  },
  "& .inputField .inputFields .MuiInputBase-root.MuiOutlinedInput-root":
  {
    width: "100%",
    height: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    borderRadius: "24px",
  },
  "& .italic": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    textDecoration: "underline",
    color: "#1A4A42",
    textDecorationColor: "green",
  },
  "& .content": {
    fontWeight: 700,
    color: "#E46760",
    fontSize: "20px",
    fontFamily: "Raleway",
    lineHeight: "23.48px"
  },
  "& .div-container": {
    marginBottom: "24px",
  },

  "& .checkBoxGroup": {
    maxWidth: "291px",
    display: "flex",
    alignItems: "center",
    maxHeight: "22px",
    marginTop: "6px",
    padding: "10px",
    borderRadius: "10px",
    marginLeft: "-10px",
  },
  "& .phoneClass": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px"
  },
  "& .datePickerStyle": {
    "& .rmdp-day": {
      color: "#0F172A",
      marginBottom: "6px",
    },
    "& .rmdp-week-day": {
      color: "#64748B",
      marginLeft: "6px",
      marginBottom: "8px"
    },
    "& .rmdp-header-values": {
      fontFamily: "Inter",
      fontWeight: 700,
      lineHeight: "22px",
      fontSize: "14px"
    },

    "& .rmdp-day-picker": {
      display: "inline"
    }
  },
  "@media screen and(max-width:450)": {
    "& .datePickerStyle": {
      width: "300px"
    }
  },

  "& .checkBoxGroup.active": {
    backgroundColor: "#000000",
  },
  "& .checkboxContainerLast": {
    display: "flex",
    marginBottom:"24px",
    alignItems: "center",
  },
  "& .singleLineCheckBox":{
    fontFamily:"Poppins",
    fontWeight:400,
    fontSize:"20px",
    lineHeight:"30px"
  },
  "& .checkboxContainer": {
    display: "flex",
    alignItems: "center",
    marginTop: "4px"
  },
  "& .upperCheckbox": {
    borderRadius: "20px"
  },
  '& .checkboxText': {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .heading": {
    fontWeight: 700,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "justify",
  },
  "& .customDropDown":{
     marginTop:"8px"
  },
  "& .stateDropDown":{
    marginTop:"-14px"
  },
  "& .emailFieldClass":{
    marginTop:"15px"
  },
  "& .box": {
    width: "100%",
    maxWidth: "600px",
    maxHeight: "340px",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    // marginTop: "32px",
    "@media screen and (max-width:750px)": {
      width: "75%"
    },
    "@media screen and (max-width:700px)": {
      width: "80%"
    },
    "@media screen and (max-width:650px)": {
      width: "80%"
    },
    "@media screen and (max-width:600px)": {
      width: "75%"
    },
    "@media screen and (max-width:500px)": {
      width: "75%"
    },
  },
  "& .headerLogo": {
    height: "auto",
    width: "100%",
    minHeight: "78px",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
  },
  "& .logoImg": {
    marginLeft: "144px",
  },
  "& .footerRight": {
    display: "flex",
    gap: "12px",
    alignItems: "center",
  },
  "& .label-700-20": {
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Poppins",
  },
  "& .main-section": {
    // height: "100vh",
    textAlign: "center",
    marginTop: "30px"
  },
  "& .custom-step-connector": {
    maxWidth: "600px",
    width: "100%",
  },
  "& .header .custom-step-connector .MuiStepLabel-labelContainer": {
    alignItems: "center",
    flexDirection: "column",
  },
  "& .about-section": {
    borderLeft: "32px solid #1A4A42;",
  },
  "& .questions": {
    fontWeight: 600,
    fontFamily: "Raleway",
    fontSize: "41px",
    lineHeight: "53.3px",
    letterSpacing: "0.5px",
    maxWidth: "700px",
    margin: "auto",
  },
  "& .information": {
    maxWidth: "600px",
    margin: "auto",
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    marginTop: "32px",
    marginBottom: "32px"
  },
  "& .callButton": {
    borderRadius: "16px",
    background: "#ffffff",
    border: "1px solid #E46760",
    padding: "5px 10px",
    color: "#E46760",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "23.48px",
  },
  "& .footer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "1px solid #E7E5E4",
    marginTop: "77.78px",
    padding: "0px 141px",
    minHeight: "96px",
    "@media(max-width:750px)": {
      padding: "0px 0px",
    },
  },
  "& .emergencyIcon": {
    display: "flex",
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "20px",
  },
  "& .button-continue": {
    padding: "10px 12px",
    border: "none",
    maxWidth: "600px",
    maxHeight: "56px",
    height: "100%",
    width: "100%",
    borderRadius: "24px",
    cursor: "pointer !important",
    background: "#E46760",
    marginTop: "32px",
    marginBottom: "28px",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "23.48px"
  },
  "& .button-continue.active": {
    border: "none",
    background: "#E46760",
    cursor: "pointer",
    color: "#FFFFFF",
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    marginTop: "32px",
    padding: "10px 12px"

  },

  "& .button-continue.inactive": {
    border: "none",
    background: "#D6D3D1",
    cursor: "not-allowed",
    color: "#FFFFFF",
    disableCursor: true,
    fontFamily: "Raleway",
    fontWeight: "700",
    fontSize: "20px",
    padding: "10px 12px",
    marginTop: "32px",
  },
  "& u": {
    color: "#D97706",
  },
  "& .header": {
    height: "auto",
    minHeight: "140px",
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  "@media screen and (max-width:1200px)": {
    "& .logoImg": {
      marginLeft: "50px",
    },
  },
  "@media screen and (max-width:767px)": {
    "& .logoImg": {
      marginLeft: "30px",
    },
  },
});

const PhoneInputWrapper = styled(Box)({
  "& .form-control": {
    width: "100%",
    height: "100%",
    maxHeight: "42px",
    maxWidth: "600px",
    paddingLeft: "15px",
    color: "#36403B",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 400,
    borderRadius: "24px",
    border: "1px solid #E6C79C"
  },
  "& .form-control::placeholder": {
    fontSize: "15px",
  },
  "& .selected-flag": {
    display: "none"
  },
  "& .flag-dropdown ": {
    border: "none"
  }
});
const webstyle = {
  footerClass: {
    marginTop: "18%",
    "@media screen and (max-width:700px)": {
      marginTop: "33%",
    },
    "@media screen and (max-width:650px)": {
      marginTop: "38%",
    },
    "@media screen and (max-width:600px)": {
      marginTop: "40%",
    },
    "@media screen and (max-width:500px)": {
      marginTop: "60%",
    },
  },

  container: {
    fontFamily: "Arial, sans-serif",
  },
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "14px",
  },
  checkboxLabelEmail: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "14px",
    margin: "0px 24px"
  },
  checkboxInput: {
    display: "none",
  },
  checkboxCustom: (isChecked: any): React.CSSProperties => ({
    width: "20px",
    height: "20px",
    border: "1px solid #78716C",
    borderRadius: "6px",
    marginRight: "10px",
    display: "inline-block",
    position: "relative",
    backgroundColor: isChecked ? "#1A4A42" : "transparent",
  }),
  checkmark: {
    position: "absolute" as "absolute", // Explicitly setting the position type
    top: "3px",
    left: "5px",
    width: "6px",
    height: "10px",
    border: "solid white",
    borderWidth: "0 2px 2px 0",
    transform: "rotate(45deg)",
    opacity: 1,
  } as React.CSSProperties,
  linkStyle: {
    color: "#00796b",
    textDecoration: "underline",
  },
  checkboxLabel1: {
    display: "flex",
    cursor: "pointer",
    fontSize: "14px",
    alignItems: "center",
  },
  checkboxInput1: {
    display: "none",
  },
  checkboxCustom1: (isChecked: any): React.CSSProperties => ({
    width: "20px",
    height: "20px",
    border: "1px solid #78716C",
    display: "inline-block",
    position: "relative",
    backgroundColor: isChecked ? "#1A4A42" : "transparent",
    borderRadius: "6px",
    marginRight: "10px",
  }),
  checkmark1: {
    width: "8px",
    height: "12px",
    border: "solid white",
    left: "5px",
    position: "absolute" as "absolute",
    top: "1px",
    transform: "rotate(45deg)",
    borderWidth: "0 2px 2px 0",
    opacity: 1,
  } as React.CSSProperties,
};


// Customizable Area End