// Customizable Area Start
import React from 'react';
import {
    styled,
    Typography,
    Box,
    Button
} from "@mui/material";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { call, emergency} from '../../blocks/email-account-registration/src/assets';

interface Props {
    data?: Object
}

interface S {
    activeStep?: Object
}

interface SS {
    id: string;
}

class SignupFooter extends BlockComponent<
Props,
S,
SS
> {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <StyledWrapper>
                <Box className="footer-section">
                    <Typography className="left-section">
                        <img src={emergency} alt="emergency"/>
                        <span className='default-text'>If this is an emergency</span><span className='emergency-call'>please call 911</span>
                    </Typography>
                    <Box className="right-section">
                        <span className="default-text need-help">Need Help?</span>
                        <CustomButton onClick={() => {}} className="call-button" data-test-id="callBTN">
                            Schedule a call?
                            <img src={call} alt="call" />
                        </CustomButton>
                    </Box>
                </Box>
            </StyledWrapper>
        );
    }
}

const StyledWrapper = styled("div")({
    width: "100%",
    "& .footer-section": {
        height: "96px",
        display: "flex",
        alignItems: "center",
        borderTop: "1px solid #E7E5E4",
        justifyContent: "space-between",
        width: "100%",
        "& .emergency-call": {
            textDecoration: "underline",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontWeight: 400,
            lineHeight: "30px",
            color: "#D97706",
            cursor: "pointer",
            "@media screen and (max-width:1000px)":{
                marginRight:"308px"
            },
            "@media screen and (max-width:750px)":{
                marginRight:"450px"
            },
            "@media screen and (max-width:700px)":{
                marginRight:"457px"
            },
            "@media screen and (max-width:500px)":{
                marginRight:"457px",
                marginLeft:"-12px"
            }
        },
        "& .left-section, & .right-section": {
            display: "flex",
            gap: "12px",
            alignItems: "center",
        },
        '& .left-section': {
            marginLeft: "109px",
            "@media screen and (max-width:1100px)":{
                marginLeft: "32px",
            },
            "@media screen and (max-width:1000px)":{
                marginLeft: "40px",
            },
            "@media screen and (max-width:700px)":{
                marginLeft: "8px",
                marginRight:"116px"
            },
            "@media screen and (max-width:650px)":{
                marginLeft: "27px",
                marginRight:"44px"
            },
            "@media screen and (max-width:600px)":{
                marginLeft: "2px",
                marginRight:"46px"
            },
            "@media screen and (max-width:550px)":{
                marginLeft: "6px",
                marginRight:"-17px"
            },
            "@media screen and (max-width:500px)":{
                marginLeft: "1px",
            },
        },
        '& .right-section': {
            marginRight: "144px",
            "@media screen and (max-width:1100px)": {
                marginRight:"35px",
              },
            "@media screen and (max-width:1000px)":{
                marginRight:"47px"
            },
            "@media screen and (max-width:850px)":{
                marginRight:"47px",
                marginLeft:"-85px"
            },
            "@media screen and (max-width:800px)":{
                marginLeft:"-131px"
            },
            "@media screen and (max-width:750px)":{
                marginLeft:"-344px"
            },
            "@media screen and (max-width:700px)":{
                width:"108px",
                marginLeft:"-327px"
            },
            "@media screen and (max-width:500px)":{
                width:"108px",
                marginLeft:"-334px"
            }
        },
        "& .need-help": {
            fontWeight: 700,
            "@media screen and (max-width:700px)":{
                marginLeft:"-96px"
        },
        "@media screen and (max-width:500px)":{
            marginLeft:"-93px"
        }
        },
    },
    "& .default-text": {
        fontFamily: "Poppins",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "30px",
        textAlign: "left"
        }
});


const CustomButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    fontFamily: "Raleway",
    lineHeight: "23.48px",
    letterSpacing: "1px",
    fontWeight: 700,
    fontSize: '20px',
    "&.call-button": {
        height: "44px",
        gap: "8px",
        borderRadius: "16px",
        border: "2px solid #E46760",
        color: "#E46760",
        padding: "0 16px",
        "@media screen and (max-width:700px)":{
           marginLeft:"-49px",
           width:"158px"
        },
        "@media screen and (max-width:500px)":{
            padding: "0 10px",
            marginRight:"11px"
        },
    },
    "&.call-button::hover": {
        border: "2px solid #E46760",
        color: "#E46760"
    }
}));

export default SignupFooter;
// Customizable Area End