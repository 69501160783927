import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleNavigation } from "../../../blocks/utilities/src/CustomHelpers";

export interface Props{
  navigation: any;
  id: string; 
}

export interface S{
  listData: {progress_bar_text: string, progress_bar_stage: string}[]
  activeStep: string,
  openSupplementModal: boolean
  supplementInsurance: boolean
}

interface SS {
    id: any;
  }

export default class InsuranceValidationController extends BlockComponent<
Props,
S,
SS
> {
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);

    this.state = {    
        openSupplementModal: false,  
      listData: [
        { progress_bar_text: 'About You', progress_bar_stage: '1' }, 
        { progress_bar_text: 'Verfiy Insurance', progress_bar_stage: '2' },
        { progress_bar_text: 'Choose Provider',  progress_bar_stage: '3'}
      ],
      activeStep: 'Verfiy Insurance',
      supplementInsurance: false 
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);        
      } 

      handleContinueButton = () => {
        handleNavigation('CheckInsurance',this.props, 'Other')
      }

      closeSupplementModal = () => {
        this.setState({openSupplementModal: false})
      }

      handleClickYes = () => {
        this.setState({supplementInsurance: true, openSupplementModal: false})
      }
}