import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import { clipBoardIcon } from '../../blocks/customform/src/assets';
import { HeaderLogoImg } from "../../blocks/email-account-registration/src/assets";

interface MyProps {
  loading: boolean;
}

export default function LoaderAnimation(props: MyProps) {
  return props.loading ? (
    <div style={webStyles.root}>
      <Box style={webStyles.header}>
        <img style={webStyles.logoImg} src={HeaderLogoImg} alt="HeaderLogoImg" />
      </Box>
      <LinearProgress color="success" value={4} />
      <Box sx={webStyles.content}>
        <Box sx={webStyles.titleBox}>
          <Typography sx={webStyles.title}>Checking your coverage...</Typography>
        </Box>
        <Box style={{marginBottom: '364px'}}>
        <img src={clipBoardIcon} alt="loader" width={76} height={98} />
        </Box>
      </Box>
    </div>
  ) : (
    <div />
  );
}

const webStyles = {
  root: {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  title: {
    fontSize: '35px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    width: '1017px',
    margin: 'auto',
  },
  content: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column', 
    backgroundColor: '#FFFAF6',
    minHeight: 'calc(100vh - 96px)'

  },
  titleBox: {
    marginTop: '200px',
    marginBottom: '193px',
    width: '100%',
  },
  header: {
    height: "auto",
    minHeight: "78px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    borderBottom: '1px solid #E7E5E4',
  },
  logoImg: {
    marginLeft: "144px",
    width: "162px",
    height: "41.24px",
  },
};