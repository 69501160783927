import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { handleNavigation } from "../../../blocks/utilities/src/CustomHelpers";

export interface Props{
  navigation: any;
  id: string; 
}

export interface S{
  listData: {progress_bar_text: string, progress_bar_stage: string}[]
  activeStep: string;
  openSupplementModal: boolean;
  supplementInsurance: boolean;
  checkMyCoverage: boolean;
   planType: string;
   zipCode: string;
   supplementalPlanType: string;
   memberID: string;
   setSelectedPlan: string;
}

interface SS {
    id: any;
  }

export default class CheckInsuranceController extends BlockComponent<
Props,
S,
SS
> {
    constructor(props: Props) {
        super(props);

        this.receive = this.receive.bind(this);

    this.state = {    
        openSupplementModal: false,  
      listData: [
        { progress_bar_text: 'About You', progress_bar_stage: '1' }, 
        { progress_bar_text: 'Verfiy Insurance', progress_bar_stage: '2' },
        { progress_bar_text: 'Choose Provider',  progress_bar_stage: '3'}
      ],
      activeStep: 'Verfiy Insurance',
      supplementInsurance: false ,
      checkMyCoverage: false,
      planType: '',
      zipCode: '',
      supplementalPlanType: 'Aetna',
      memberID: '',
      setSelectedPlan: ''
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);        
      } 

      async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start

        // Customizable Area End
      }

      handleContinueButton = () => {
        this.setState({openSupplementModal: true})
      }

      closeSupplementModal = () => {
        this.setState({openSupplementModal: false})
      }

      handleClickYes = () => {
        this.setState({supplementInsurance: true, openSupplementModal: false})
      }

      handleCheckMyCoverage = () => {
        this.setState({checkMyCoverage: true, openSupplementModal: false})

        setTimeout(()=> {
        this.setState({checkMyCoverage: false})
        handleNavigation("InsuranceValidation", this.props);
        }, 5000)
      }

        handlePlanChange = (event:any) => {
            this.setState({ planType: event.target.value as string });
        };

        handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ zipCode: event.target.value });
        };

        handleSupplementChange = (event: { target: { value: string; }; }) => {
          this.setState({setSelectedPlan: event.target.value});
      };

        handleMemberIDChange = (event: { target: { value: string; }; }) => {
          this.setState({memberID: event.target.value});
        };
        
    }