import React from 'react';
import {
    Box, styled, Typography, FormControl, Select, MenuItem, TextField, InputAdornment, OutlinedInput
} from "@mui/material";
import CheckInsuranceController, { Props } from "./CheckInsuranceController";
import StepperData from '../../../components/src/StepperData.web';
import FooterData from "../../../components/src/SignupFooter.web";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import SupplementModal from '../../../components/src/SupplementModal.web';
import LoaderAnimation from '../../../components/src/LoaderAnimation.web';

class CheckInsurance extends CheckInsuranceController {
    constructor(props: Props) {
        super(props);
    }
    
    renderPlanTypeSelect = () => {
        return(
            <FormControl fullWidth sx={{ mb: 2 }}>
            <label htmlFor="plan-type" className='type-label'>Plan Type</label>
            <Select
                data-test-id="planType"
                labelId="plan-type"
                value={this.state.planType}
                onChange={this.handlePlanChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                    PaperProps: {
                    sx: {
                        border: "1px solid #E6C79C",
                        borderRadius: "0px 0px 24px 24px",
                    },
                    },
                }}
                sx={{
                    padding: '12px 14px',
                    fontWeight: 400,
                    borderRadius: '70px',
                    backgroundColor: '#f5f5f5',
                    border: '1px solid #D6D3D1',
                    fontFamily: 'Poppins',
                    fontSize: '20px',
                    color: '#78716C',
                    '.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    '.MuiOutlinedInput-input': {
                        padding: '0px', 
                    },
                }}
            >
                <MenuItem value="" sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>
                Original Medicare
                </MenuItem>
                <MenuItem value={10} sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>Option 1</MenuItem>
                <MenuItem value={20} sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>Option 2</MenuItem>
            </Select>
                </FormControl>
        )
    }
    renderZipCodeInput = () => {
        return(
            <FormControl sx={{ mb: 2 }}>
            <label htmlFor="zip-code" className='type-label'>Zip Code</label>
            <TextField
                fullWidth
                variant="outlined"
                value={this.state.zipCode}
                onChange={this.handleZipCodeChange}
                sx={{
                    borderRadius: '70px',
                    background: '#FFFFFF',
                    width: '292px',
                    border: '1px solid #E6C79C',
                    '.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                    input: {
                        fontFamily: 'Poppins', 
                        fontSize: '20px', 
                        color: '#78716C',
                        padding: '10px',
                    },
                }}
                inputProps={{ 'aria-label': 'Zip Code' }}
            />
        </FormControl>
        )
    }
    renderSupplementProvider = () => {
        return(
            <FormControl fullWidth sx={{ mb: 2 }}>
            <label htmlFor="plan-type" className='type-label'>Secondary / Supplemental plan provider</label>
            <Select
                labelId="plan-type-label"
                label="Aetna"
                inputProps={{ 'aria-label': 'Aetna' }}
                placeholder='Aetna'
                IconComponent={KeyboardArrowDownIcon}
                MenuProps={{
                    PaperProps: {
                      sx: {
                        border: "1px solid #E6C79C",
                        borderRadius: "0px 0px 24px 24px",
                      },
                    },
                  }}
                sx={{
                    borderRadius: '70px',
                    background: 'none',
                    border: '1px solid #E6C79C',
                    color: 'A8A29E',
                    fontSize: '20px',
                    fontFamily: 'Poppins',
                    '.MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                    },
                }}
                displayEmpty
                data-test-id="supplement-change"
                value={this.state.setSelectedPlan} 
                onChange={this.handleSupplementChange} 
                input={
                    <OutlinedInput
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon sx={{ color: '#A8A29E', width: '24px', height: '24px' }} />
                            </InputAdornment>
                        }
                    />
                }
            >
                <MenuItem value="" disabled sx={{ fontSize: '16px', fontFamily: 'Poppins' }}>
                    Aetna
                </MenuItem>
                <MenuItem sx={{ fontSize: '16px', fontFamily: 'Poppins' }} value="Option 1">Option 1</MenuItem>
                <MenuItem sx={{ fontSize: '16px', fontFamily: 'Poppins' }} value="Option 2">Option 2</MenuItem>
            </Select>
        </FormControl>
        )
    }
    renderMemberInput = () => {
        return(
            <FormControl fullWidth sx={{ mb: 2 }}>
            <label htmlFor="plan-type" className='type-label'>Member ID</label>
            <TextField
            fullWidth
            variant="outlined"
            value={this.state.memberID}
            placeholder='1 E G 4 - T E 5 - M K 7 2'
            onChange={this.handleMemberIDChange}
            sx={{
            borderRadius: '70px',
            background: '#FFFFFF',
            border: '1px solid #E6C79C',
            color: '#A8A29E',
            fontSize: '20px',
            fontFamily: 'Poppins',
            input: {
                fontSize: '20px',
                fontFamily: 'Poppins',
                fontWeight: 400,
                color: '#A8A29E',
            },
            '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
            },
            }}
            inputProps={{ 'aria-label': 'Member ID' }}
        />
        </FormControl>
        )
    }

    renderContent() {
        const { supplementInsurance } = this.state;
        const unique_slug = this.props.navigation.getParam("navigationBarTitleText") || '';
    
        if (unique_slug === 'Original Medicare') {
            if (!supplementInsurance) {
                return (
                    <>
                        {this.renderPlanTypeSelect()}
                        {this.renderZipCodeInput()}
                    </>
                );
            } else {
                return (
                    <>
                        {this.renderSupplementProvider()}
                        {this.renderMemberInput()}
                    </>
                );
            }
        } else {
            return (
                <>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <label htmlFor="plan-type" className='type-label'>Secondary / Supplemental plan provider</label>
                        <Select
                            labelId="Aetna"
                            label="Placeholder"
                            inputProps={{ 'aria-label': 'Aetna' }}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: '70px',
                                background: 'none',
                                border: '1px solid #E6C79C',
                                color: 'A8A29E',
                                fontSize: '20px',
                                fontFamily: 'Poppins',
                                '.MuiOutlinedInput-notchedOutline': {
                                    border: 'none',
                                },
                            }}
                            displayEmpty 
                            value=""
                            input={
                                <OutlinedInput
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ color: '#A8A29E', width: '24px', height: '24px' }} />
                                        </InputAdornment>
                                    }
                                />
                            }
                        >
                            <MenuItem value="" disabled>
                                Placeholder
                            </MenuItem>
                            <MenuItem value={10}>Option 1</MenuItem>
                            <MenuItem value={20}>Option 2</MenuItem>
                        </Select>
                    </FormControl>
                    {this.renderMemberInput()}
                    {this.renderZipCodeInput()}
                </>
            );
        }
    }
    
    renderButtons = () => {
        const {supplementInsurance, setSelectedPlan, memberID, zipCode, planType} = this.state
        return(
            supplementInsurance ? 
                <button className="button"
               style={{ background: memberID || setSelectedPlan ? "#E46760" : '#c9c8c8'}}
                disabled={!zipCode} onClick={this.handleCheckMyCoverage}>Continue</button>
                 :
               <button
               data-test-id="continue-btn"
               style={{ background: zipCode || planType ? "#E46760" : '#c9c8c8'}}
               className="button" disabled={!zipCode} onClick={this.handleContinueButton}>Continue</button>
        )
    }
    render() {
    return (
            <>
            {this.state.checkMyCoverage ? <LoaderAnimation loading={true}/> :
            <StyledWrapperCheckInsurance>    
                <section className='leftBorder'>
                    <Box className="mainContainer">

                        <Box className="headerStepper">
                            <StepperData
                                key={`stepper${this.state.activeStep}`}
                                listData={this.state.listData}
                                activeStep={this.state.activeStep}
                            />
                        </Box>
                        <Box className="heading">
                            <Typography className="title">{this.state.supplementInsurance ? 'Supplemental Insurance' : 'Primary Insurance'}</Typography>
                            <Typography className="information">{this.state.supplementInsurance ? "Please enter any other secondary or supplemental insurance cards that you hold"
                                : "This information will be used to verify your coverage. Please ensure details are accurate."}</Typography>
                        </Box>
                        <Box className="boxes">
                            <Box className="box1">
                                <Box className="mainContentBox">
                                    <Box className="content">
                                       {this.renderContent()}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ display: 'block', marginBottom: '149.3px' }}>
                           {this.renderButtons()}
                            {this.state.openSupplementModal && 
                            <SupplementModal open={this.state.openSupplementModal} 
                                             onClose={this.closeSupplementModal}
                                             data-test-id='supplementModal'
                                             handleClickYes={this.handleClickYes}
                                             handleCheckMyCoverage={this.handleCheckMyCoverage}
                                             />}
                            {!this.state.supplementInsurance && <button className="secondary-button">I don't have my insurance details on me</button>}
                        </Box>
                    </Box>
                    <FooterData />
                </section>
            </StyledWrapperCheckInsurance>
        }
        </>
        )
    }
}

const input = (
    <OutlinedInput
        startAdornment={
            <InputAdornment position="start">
                <SearchIcon />
            </InputAdornment>
        }
        sx={{ width: '300px', height: '40px' }}
    />
);

const StyledWrapperCheckInsurance = styled('div')({
    width: "100%",
    margin: "0 auto",
    "& *": {
        boxSizing: "border-box",
    },
    "& .information": {
        maxWidth: "600px",
        margin: "auto",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        color: "#2C2524",
        lineHeight: "36px",
        textAlign: "center",
        marginBottom: '40px'
    },
    "& .heading": {
        margin: "auto",
        fontWeight: 400,
    },
    "& .button": {
        margin: "auto",
        marginBottom: '32px',
        "display": "flex",
        "justifyContent": "center",
        width: "600px",
        padding: '16.5px',
        border: "none",
        borderRadius: '50px',
        color: "white",
        fontSize: "20px",
        fontFamily: "Raleway",
        fontWeight: 700,
        alignItems: "center",
        letterSpacing: '1px'
    },
    "& .secondary-text": {
        display: "flex",
        justifyContent: "center",
        lineHeight: "23.48px",
        letterSpacing: "1px",
        fontFamily: "Raleway",
        fontWeight: 700,
        fontSize: "20px",
        color: "#807C7C",
        border: "none",
        background: "none",
        cursor: "pointer",
        margin: 'auto',
        textDecoration: 'underline',
        marginBottom: '40px',
        marginTop: '24px',
    },
    '& .type-label': {
        color: '#2C2524',
        fontSize: "16px",
        fontFamily: "Poppins",
        marginBottom: '4px',
        fontWeight: 700,
    },
    "& .secondary-button": {
        display: "flex",
        justifyContent: "center",
        lineHeight: "23.48px",
        letterSpacing: "1px",
        fontFamily: "Raleway",
        fontWeight: 700,
        fontSize: "20px",
        color: "#E46760",
        border: "none",
        background: "none",
        cursor: "pointer",
        margin: 'auto',
        textDecoration: 'underline',
    },
    '& .mainContentBox': {
        padding: '32px 20px'
    },
    "& .image": {
        height: "20%",
        width: "20%",
    },
    "& .box1": {
        margin: 'auto',
        width: '640px',
        border: "1px solid #D6D3D1",
        borderRadius: "24px",
        background: '#FFFFFF'
    },
    "& .boxes": {
        height: "auto",
        width: "100%",
        marginBottom: "60px",
        display: "flex",
        flexDirection: "row",
        gap: "20px"
    },
    "& .title": {
        width: "100%",
        height: "auto",
        maxWidth: "60%",
        color: "#2C2524",
        margin: "60px auto 20px ",
        fontWeight: 700,
        fontSize: "41px",
        fontFamily: "Raleway",
        textAlign: "center",
        LineHeight: '60px',
        LetterSpacing: '0.5px'
    },
    "& .leftBorder": {
        height: "auto",
        borderLeft: "32px solid #1A4A42",
    },
    "& .headerStepper": {
        "display": "flex",
        "justifyContent": "center",
    },
    "& .boxTitle": {
        fontWeight: 500,
        letterSpacing: '0.5px',
        "fontFamily": "Poppins",
        "textAlign": "center",
        marginBottom: '32px',
        "fontSize": "29px",
        width: "100%",
        "color": "#2C2524",
    },
})

export default CheckInsurance;