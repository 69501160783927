import React from 'react';
import {
    Box, styled, Typography
} from "@mui/material";
import InsuranceValidationController, { Props } from "./InsuranceValidationController.web";
import StepperData from '../../../components/src/StepperData.web';
import { callImage, insuraneOne, insuraneThree, insuraneTwo, successImage, walletImage } from './assets';
import { handleNavigation } from '../../../blocks/utilities/src/CustomHelpers';

class InsuranceValidation extends InsuranceValidationController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <StyledWrapperCheckInsurance>
                <section className='leftBorder'>
                    <Box className="mainContainer">

                        <Box className="headerStepper">
                            <StepperData
                                key={`stepper${this.state.activeStep}`}
                                listData={this.state.listData}
                                activeStep={this.state.activeStep}
                            />
                        </Box>
                        <Box className="success-container">
                        <Box className="image-container">
                            <img src={successImage} alt="image" width={128} height={128}/>
                        </Box>
                        <Box className="heading">
                            <Typography className="title">Congratulations!</Typography>
                            <Typography className="information">
                            You have insurance coverage.
                            <span style={{ display: 'block' }}>Click continue to select your provider.</span>
                            </Typography>
                            <button className="button" onClick={this.handleContinueButton}>Continue</button>
                        </Box>
                        </Box>

                        <Box className="failed-container">
                        <Box className="image-container">
                            <img src={walletImage} alt="image" width={128} height={96}/>
                        </Box>
                        <Box className="heading">
                            <Typography className="failed-title">We are not in network with
                            your insurance at this time</Typography>
                            <Typography className="failed-information">
                            You are welcome to continue booking sessions on a cash pay basis
                            </Typography>
                            <button className="failed-button">Book a Session</button>
                            <button className="secondary-button">Cancel</button>
                            <button className="secondary-text">Return to insurance details</button>
                        </Box>
                        </Box>

                        <Box className="pending-container">
                            <Box className="heading">
                                <Typography className="pending-title">We couldn't verify your insurance</Typography>
                                <Typography className="pending-information">We can call you, or please select one of the following:</Typography>
                                <Box className="three-column-tile-container">
                                    <Box className="three-column-tile-box-Container">
                                        <Box
                                            className="boxes"
                                        >
                                            {<img src={insuraneOne} className="tile-images" alt="image" width={72} height={72}/>}
                                            <Typography className="tileTitle">Re-submit my insurance details</Typography>
                                            <button
                                                data-test-id={'continuebtn'}
                                                className={`tileBtn`} 
                                                onClick={()=>handleNavigation('InsuranceVerify',this.props)}
                                                >Re-submit</button>
                                        </Box>
                                        <Box
                                            className="boxes"
                                        >
                                            {<img src={insuraneTwo} className="tile-images" alt="image" width={72} height={72}/>}
                                            <Typography className="tileTitle">Continue without insurance</Typography>
                                            <button
                                                data-test-id={'continuebtn'}
                                                className={`tileBtn`} >Continue</button>
                                        </Box>
                                        <Box
                                            className="boxes"
                                        >
                                            {<img src={insuraneThree} className="tile-images" alt="image" width={72} height={72} />}
                                            <Typography className="tileTitle">Speak to alive receptionist via video</Typography>
                                            <button
                                                data-test-id={'continuebtn'}
                                                className={`tileBtn`} >Join Queue</button>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box className="call-box-container">
                                <button className="pending-secondary-text">Or call our team now on:</button>
                                <button className="pending-secondary-button">1-800-567-LIFE <img src={callImage} width={30} height={30}/></button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </section>
            </StyledWrapperCheckInsurance>
        )
    }
}

const StyledWrapperCheckInsurance = styled('div')({
    width: "100%",
    margin: "0 auto",
    backgroundColor: '#FFFAF6',
    borderLeft: "32px solid #1A4A42",
    "& *": {
        boxSizing: "border-box",
    },
    "& .image-container": {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '173px'
    },
    "& .information": {
        maxWidth: "600px",
        margin: "auto",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        color: "#2C2524",
        lineHeight: "36px",
        textAlign: "center",
        marginBottom: '40px'
    },
    "& .heading": {
        margin: "auto",
        fontWeight: 400,
    },
    "& .success-container": {
        width: '478px',
        margin: 'auto',
    },
    "& .button": {
        alignItems: "center",
        margin: "auto",
        marginBottom: '265px',
        "display": "flex",
        "justifyContent": "center",
        width: "392px",
        padding: '16.5px',
        letterSpacing: '1px',
        background: "#E46760",
        color: "white",
        border: "none",
        borderRadius: '50px',
        fontSize: "20px",
        fontFamily: "Raleway",
        fontWeight: 700,
    },
    "& .secondary-text": {
        color: "#807C7C",
        display: "flex",
        fontWeight: 700,
        textDecoration: 'underline',
        margin: 'auto',
        justifyContent: "center",
        letterSpacing: "1px",
        fontFamily: "Raleway",
        fontSize: "20px",
        background: "none",
        marginBottom: '40px',
        marginTop: '24px',
        cursor: "pointer",
        border: "none",
    },
    "& .image": {
        height: "20%",
        width: "20%",
    },
    "& .box1": {
        margin: 'auto',
        width: '640px',
        border: "1px solid #D6D3D1",
        borderRadius: "24px",
        background: '#FFFFFF'
    },
    "& .title": {
        color: "#2C2524",
        width: "100%",
        height: "auto",
        fontFamily: "Raleway",
        margin: "40px auto",
        fontWeight: 600,
        LineHeight: '60px',
        textAlign: "center",
        fontSize: "41px",
        LetterSpacing: '0.5px'
    },
    "& .headerStepper": {
        "display": "flex",
        "justifyContent": "center",
    },
    "& .failed-title": {
        width: "100%",
        height: "auto",
        maxWidth: "404px",
        color: "#2C2524",
        margin: "40px auto",
        fontWeight: 600,
        fontSize: "29px",
        fontFamily: "Poppins",
        textAlign: "center",
        LineHeight: '60px',
        LetterSpacing: '0.5px'
    },
    "& .failed-information": {
        maxWidth: "478px",
        margin: "auto",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        color: "#2C2524",
        lineHeight: "36px",
        textAlign: "center",
        marginBottom: '40px'
    },
    "& .failed-container": {
        width: '478px',
        margin: 'auto',
        marginBottom: '207px'
    },
    "& .secondary-button": {
        margin: "auto",
        "display": "flex",
        letterSpacing: '1px',
        width: "392px",
        padding: '16.5px',
        background: "none",
        border: "1px solid #E46760",
        borderRadius: '50px',
        color: "#E46760",
        fontSize: "20px",
        "justifyContent": "center",
        fontFamily: "Raleway",
        fontWeight: 700,
        alignItems: "center",
        marginBottom: '20px',
    },
    "& .failed-button": {
        margin: "auto",
        marginBottom: '20px',
        "display": "flex",
        "justifyContent": "center",
        width: "392px",
        padding: '16.5px',
        background: "#E46760",
        border: "none",
        borderRadius: '50px',
        color: "white",
        fontSize: "20px",
        fontFamily: "Raleway",
        fontWeight: 700,
        alignItems: "center",
        letterSpacing: '1px'
    },
    "& .pending-title": {
        width: "100%",
        height: "auto",
        // maxWidth: "404px",
        color: "#2C2524",
        margin: "60px auto 32px",
        fontWeight: 600,
        fontSize: "41px",
        fontFamily: "Raleway",
        textAlign: "center",
        LineHeight: '53.3px',
        LetterSpacing: '0.5px'
    },
    "& .pending-information": {
        maxWidth: "531px",
        width:'531px',
        margin: "auto",
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "20px",
        color: "#2C2524",
        lineHeight: "36px",
        textAlign: "center",
        marginBottom: '75px'
    },
    "& .pending-button": {
        margin: "auto",
        marginBottom: '20px',
        "display": "flex",
        "justifyContent": "center",
        width: "531px   ",
        padding: '16.5px',
        background: "#E46760",
        border: "none",
        borderRadius: '50px',
        color: "white",
        fontSize: "20px",
        fontFamily: "Raleway",
        fontWeight: 700,
        alignItems: "center",
        letterSpacing: '1px'
    },
    "& .three-column-tile-box-Container": {
        width: "100%",
        maxWidth: "912px",
        margin: "auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
    },
    "& .tile-images": {
        height: "72px",
        width: "72px",
        marginBottom: '28px'
    },
    "& .tileTitle": {
        width: "auto",
        textAlign: "center",
        height: "auto",
        fontFamily: "Poppins",
        fontWeight: 700,
        fontSize: "20px",
        paddingTop: "12px",
        marginBottom:'28px'
    },
    "& .tileBtn": {
        cursor: 'pointer',
        width: "184px",
        padding: "6px 10px",
        height: "auto",
        color: "white",
        border: "none",
        borderRadius: "16px",
        background: "#E46760",
        flexShrink: 0,
        marginBottom: '32px',
        fontWeight: '700'
    },
    "& .boxes": {
        flexGrow: 1,
        flexBasis: "calc(33.33% - 20px)",
        minWidth: "250px",
        margin: "10px",
        border: "1px solid #D6D3D1",
        borderRadius: "16px",
        justifyContent: "space-between",
        padding: "16px",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    '& .call-box-container': {
        minWidth: "700px",
        display: 'flex',
        padding: '24px 32px',
        border: '1px solid #D6D3D1',
        width: '700px',
        gap: '10px',
        justifyContent: 'center',
        margin: 'auto',
        borderRadius: '16px',
        marginTop: '60px',
        marginBottom: '178px'
    },
    "& .pending-secondary-text": {
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "24px",
        color: "#2C2524",
        border: "none",
        background: "none",
        cursor: "pointer",
        textDecoration: 'underline',
    },
    "& .pending-secondary-button": {
        "display": "flex",
        "justifyContent": "center",
        padding: '16.5px',
        background: "none",
        border: "3px solid #E46760",
        borderRadius: '50px',
        fontSize: "29px",
        fontFamily: "Poppins",
        fontWeight: 700,
        alignItems: "center",
        letterSpacing: '0.5px',
        color: 'black',
        gap: '8px'
    },
})

export default InsuranceValidation;