import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import  {RefObject, createRef } from 'react';
// Customizable Area Start

interface FormValues {
  phone_numbers: string[];
  landline_number: string[];
  email: string[];
  freeTextField: string[];
  customDropDown: string[];
  phone_checkboxes: { [key: string]: boolean }; 
  contact_method: string[];
  reason_for_signing_up: string;
  relationship_to_patient: string;
  dateOfBirth: string;
}
interface FormDataStructure {
  suffix_label: any;
  id: number | null;
  form_header: string;
  form_description: string;
  email: boolean;
  email_req : boolean;
  sign_up_with_google: boolean;
  phone_number: boolean;
  phone_number_req : boolean;
  contact_method: boolean;
  contact_method_req : boolean;
  name: boolean;
  progress_bars: {progress_bar_text: string, progress_bar_stage: string}[];
  stage_type: string;
  email_label : string;
  phone_number_label : string;
  contact_method_label : string;
  help_footer : boolean;
}

interface SignupData {
  data: SignUpFormValue
}
interface SignUpFormValue {
  id: string;
  type: string;
  attributes: Attributes;
}
interface Attributes {
  page_title: string;
  main_title: string | null;
  main_title_section_1: string | null;
  sub_title_section_1: string | null;
  main_body_section_1: string | null;
  main_body: string;
  select_onboarding_page_type: string;
  unique_slug: string;
  activated: boolean;
  main_title4: string;
  sub_title4: string;
  help_footer: boolean;
  image_urls: ImageUrls;
  sign_up_form_sections: SignUpFormSection[];
  match_making_sections: Record<string, unknown>; 
  primary_links_attributes: LinkAttributes[];
  primary_links_section2_attributes: LinkAttributes[];
  primary_links_section4_attributes: PrimaryLinkSection4[];
  primary_links_section3_attributes: LinkAttributes[];
  secondary_links_section3_attributes: LinkAttributes[];
}
interface LinkAttributes {
  external_link: boolean ,
  secondary_button_text: string ,
  secondary_link_url: string 

}
interface ImageUrls {
  image: string | null;
}

interface SignUpFormSection {
  field_type: string;
  options: FieldOption[];
}

interface FieldOption {
  id: number;
  label: string;
  is_mandatory: boolean;
  content: string | null;
}

interface PrimaryLinkSection4 {
  external_link: boolean;
  primary_button_text: string;
  primary_link_url: string;
}
interface Errors {
  [key: string]: string; // Allows for dynamic keys with string values
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  closeCalendar:boolean;   
  stateDropdown:string[];
  selectedValue: string | [];
  agreeChecked: boolean;
  skipped: Set<number>;
  formStats: FormDataStructure;
  signUPFormvalue:SignupData;
  identificationContactDetails :{secondary_mobile_number: { value: string }}  
  modalOpen:boolean;
  apiPageType:string,
  role:string;
  landline_no :boolean[];
  therapyEmail:string;
  therapyLadlineNo:any;
  therapyPhoneNo:any;
  phoneCheckbox:boolean;
  selectedMethods:any;
  emailCheckbox:boolean;
  smsCheckbox:boolean;
  postApiId:any;
  consentChecked:boolean;
  open:boolean;
  patientRelation:string;
  patientReasons:string;
  dateValue:any;
  simpleTextField:string;
  phone_numbers:any;
  email_Field:any;
  landline_numbers:any;
  therapyLandlineNo:any;
  landline_number:any;
  allError:any;
  freeTextField:any;
  message:string;
  dropDownState:any;
  customDropDownState:any;
  dateOfBirth:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WellnessJourneyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getQuestionStatsCallId: string = "";
  postTherapyApiCallId:string = "";
  postSignupApiCallId:string = "";
  getStatsCallIds:string = "" ;
  dropDownRef: RefObject<any>;
  datePickerRef:RefObject<any>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      stateDropdown: [],
      dropDownState:"",
      customDropDownState:"",
      landline_numbers:[],
      therapyLandlineNo:[],
      landline_number:[],
      freeTextField:[],
      dateOfBirth:"",
      
      allError: {
        phoneNUmber:"",
        emailField:"",
        multipleCheckBoxError:"",
        freeTextFieldError:"",
        dropDownStateError:"",
        customDropDownError:"",
        dateOfBirthError:""
      },
      phone_numbers:[],
      email_Field:[],
      dateValue:"",
      closeCalendar:false,
      patientReasons:"",
      patientRelation:"",
      selectedValue: [],
      selectedMethods:[],
      postApiId:"",
      therapyEmail:"",
      therapyLadlineNo:"",
      phoneCheckbox:false,
      emailCheckbox:false,
      smsCheckbox:false,
      therapyPhoneNo:"",
      modalOpen:false,
      apiPageType:"",
      role:"",
      skipped: new Set(),
      agreeChecked: false,
      landline_no: [],
      simpleTextField:"",
      formStats:{
        id: null,
        form_header: "",
        form_description: "",
        email: false,
        email_req : false,
        phone_number: false,
        phone_number_req : false,
        contact_method: false,
        contact_method_req : false,
        name: false,
        sign_up_with_google: false,
        progress_bars: [],
        stage_type: '',
        email_label : '',
        phone_number_label : '',
        contact_method_label :'',  
        help_footer : false,
        suffix_label: "",   
      },
      signUPFormvalue: {data:{
        id: '',
        type: '',
        attributes: {
          page_title: '',
          main_title: "",
          main_title_section_1: "",
          sub_title_section_1: "",
          main_body_section_1: "",
          main_body: '',
          select_onboarding_page_type: "",
          unique_slug: '',
          activated: true,
          main_title4: "",
          sub_title4: "",
          help_footer: false,
          image_urls: {
            image: null,
          },
          sign_up_form_sections: [],
          match_making_sections: {},
          primary_links_attributes: [],
          primary_links_section2_attributes: [],
          primary_links_section4_attributes: [
            {
              external_link: false,
              primary_button_text: 'Continue',
              primary_link_url: 'match_making_template/aaa',
            },
          ],
          primary_links_section3_attributes: [],
          secondary_links_section3_attributes: [],
        },
}
      },
      consentChecked:false,
      open:false,
      identificationContactDetails: {secondary_mobile_number: { value: "" }},
      message:"",
     

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.dropDownRef = createRef(); 
    this.datePickerRef = createRef(); 
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    this.handleResForQuestionStats(from,message)
    this.handlePostSignUpApi(from,message)
    this.handlePostApiResponse(from,message);
    this.handleResForStateApi(from,message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.getApis();
    this.getStataApi();
    this.postSignupApi();
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  async componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside); 
  }
 
   handleClickOutside = (event: MouseEvent) => {
      if (this.dropDownRef.current && !this.dropDownRef.current.contains(event.target)) {
        this.setState({ closeCalendar: false });
        if (this.datePickerRef.current) {
          this.datePickerRef.current.closeCalendar(); 
        }
      }
   }
   handleCheckboxChange = (item:any) => (event:any) => {
    const isChecked = event.target.checked;
    const { selectedMethods } = this.state;

    if (isChecked) {
      if (!selectedMethods.includes(item)) {
        this.setState({
          selectedMethods: [...selectedMethods, item],
        });
      }
    } else {
      this.setState({
        selectedMethods: selectedMethods.filter((method:any) => method !== item),
      });
    }
  };
  componentDidUpdate() {
    document.title = `${this.state.signUPFormvalue?.data?.attributes?.page_title}`;
  }
  
  
  handleConsentChange = (value: boolean) => {
    this.setState({ consentChecked: value });
  };
  getApis = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionStatsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_landingpage2/landing_page_questions/get_variant_form_data?form_type=patient"
      
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStataApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStatsCallIds = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStateApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  
  handlePostSignUpApi = async (from: string, message: Message) => {
    if (
      this.postSignupApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        if (!responseJson.errors && !responseJson.error_message) {
          this.setState({signUPFormvalue:responseJson, message: ''});
        }
        if (!responseJson.errors && responseJson.error_message) {
          this.setState({ message: "Page not found"});
        }
        if (responseJson.errors) {
          this.setState({ message: "Page not found"});
        }
      }
    }
  };
  handleResForQuestionStats = async (from: string, message: Message) => {
    if (
      this.getQuestionStatsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        this.setState({formStats:responseJson})
      }
    }
  };
  
  handleNavigation = (pathname: any) => {
    const paramsData = pathname.split('/');
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), paramsData[0]);
    message.addData(getName(MessageEnum.NavigationPropsMessage), {...this.props});
    paramsData[1] && message.addData(getName(MessageEnum.NavigationScreenNameMessage), paramsData[1]);
    this.send(message);
  }




  handleNavigationWellness = (pathname: string, props: unknown, param?: any) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), pathname);
    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    param && message.addData(getName(MessageEnum.NavigationScreenNameMessage), param);
    runEngine.sendMessage(message.id, message)
  };
  handlePostApiResponse = async (from: string, message: Message) => {
    if (
      this.postTherapyApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(responseJson){
        this.setState({postApiId:responseJson.data.id} , () => {
          this.handleNavigationWellness("WellnessJourneyUpdate", this.props, this.state.postApiId)

        })

      }

    }
  };

  handleResForStateApi = async (from: string, message: Message) => {
    if (
      this.getStatsCallIds ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ stateDropdown: responseJson.states });
      }
    }
  };
  handleAgreeChanges = (value: boolean) => {
    this.setState({ agreeChecked: value });
  };

  postTherapySignupApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    
    let data = {
      data: {
      type: "patients",
      attributes: {
      email: this.state.therapyEmail,
      phone_number: this.state.therapyPhoneNo ? this.state.therapyPhoneNo : this.state.therapyLadlineNo,
      contact_method: this.state.selectedMethods
    }
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postTherapyApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postTherapyDataApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  postSignupApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const unique_slug = this.props.navigation.getParam("navigationBarTitleText") || '';
    let data = {
       unique_slug,
       page_type: "SignUp Form Template"
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postSignupApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_landingpage2/landing_page_questions/get_multiple_page_instance"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 
  // Customizable Area End
}